import styled from "styled-components";

export const Container = styled.div`
  background: #000;
  grid-area: f;
  font-size: 1.5rem;

  div {
    color: #0f0f0f;
  }
`;
