export const service = {
  getExperiencesFull() {
    return [
      {
        "id": "1",
        "locale":"Stefanini",
        "function": "Java Developer",
        "period": "JUL/2012 - JAN/2014",
        "image": "stefanini.png",
        "description":"",
      },
      {
        "id": "2",
        "locale":"Acol Consultoria & Sistemas",
        "function": "Java Developer",
        "period": "JAN/2014 - ABR/2014",
        "image": "acol.png",
        "description":"",
      },
      {
        "id": "3",
        "locale":"webamericas",
        "function": "Javascript Developer",
        "period": "JUL/2014 - FEV/2015",
        "image": "webamericas.png",
        "description":"",
      },
      {
        "id": "4",
        "locale":"Metatron",
        "function": "Fullstack Developer",
        "period": "FEV/2015 - ABR/2018",
        "image": "metatron.jpeg",
        "description":"Participei no desenvolvimento e manutenção de uma aplicação web (OiVende) que era usado pelos lojistas da Oi para venda de planos de internet, celular e TV. Aplicação tinha como função apresentar viabilidade técnica de instalação, velocidade disponível, produtos disponíveis para a região do cliente e etc. Buscando essas informações de diversas fontes como APIs e Banco de Dados.\nEssa aplicação usava tecnologias como JSP, HTML, JS, CSS, LumisPortal, Java, Hibernate, Spring\nAtividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerenciar pacotes de entrega, acompanhamento das entregas em produção.",
      },
      {
        "id": "5",
        "locale":"Radix Engenharia e Software",
        "function": "Fullstack Developer",
        "period": "MAI/2018 - JAN/2021",
        "image": "radix.jpeg",
        "description":"Participei em diversos projetos do grupo Ipiranga e um dos projetos foi o desenvolvimento de uma aplicação que consultava os preços de compra dos combustíveis em grandes distribuidoras. Essa aplicação realizava cálculos com base em dados relevantes, como transporte do combustível, impostos, preços do dia, etc. \nAs tecnologias utilizadas eram Angular, Java, Spring, Hibernate, OracleDB, JUnit e a metodologia ágil foi aplicada para gerenciar as demandas do projeto.\nAlém disso, colaborei no desenvolvimento de uma aplicação mobile que facilitava a realização de pedidos de combustíveis para postos de gasolina. Essa aplicação foi construída usando tecnologias como React Native, Node.js, Express, etc.\nAtividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerenciar pacotes de entrega, repasse de projeto para outros times.",
      },
      {
        "id": "6",
        "locale":"Oi S/A",
        "function": "Fullstack Developer",
        "period": "JAN/2021 - OUT/2021",
        "image": "oi.jpeg",
        "description":"Participei de um time que era responsável pela verificação do repositório para identificar sites obsoletos e implementaria a higienização e atualização do layout de vários sites legados, criação de páginas novas, evolução e manutenção no backend.  \nAs tecnologias envolvidas incluíam HTML, CSS, JavaScript, React, JSP, Node, Java.\nAtividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerenciar pacotes de entrega.",
      },
      {
        "id": "7",
        "locale":"Vtal",
        "function": "Fullstack Developer",
        "period": "OUT/2021 - ABR/2022",
        "image": "vtal.jpeg",
        "description":"Implementação de Gateway para governança das APIs que são expostas aos clientes da infraestrutura da Vtal. Construção da página PortalDEV, que apresenta as APIs com suas documentações, Swagger e histórico de versionamento.\nUsamos tecnologias como React, Java, Node, API Gateway.\nAtividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerencia dos pacotes de entrega.",
      },
      {
        "id": "8",
        "locale":"Foursys",
        "function": "Fullstack Developer",
        "period": "ABR/2022 - FEV/2024",
        "image": "foursys.jpeg",
        "description":"Participei da evolução e manutenção do aplicativo móvel do Bradesco (App disponível para correntistas do banco). Com foco na área de investimentos do Bradesco, onde criei diversas telas, desenvolvi e mantive serviços. \nCriamos e alteramos micro frontends em angular para apresentar produtos novos (de parceiros como a Ágora), apresentar informações de novas funcionalidades e marketing. Os serviços são divididos em micro serviços como Node e Java e usamos ferramentas como Redis, Kafka no apoio das soluções.\nAtividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerenciar pacotes de entrega, repasse de artefatos para outros times, acompanhamento das entregas em produção, monitoramento da saúde de sistemas, análise de incidentes.\nAs principais demandas envolviam as tecnologias Angular, Node.js e Java.",
      },
      {
        "id": "9",
        "locale":"Neoris",
        "function": "Fullstack Developer",
        "period": "FEV/2018 - ABR/2024",
        "image": "neoris.jpeg",
        "description":"Participei de um projeto para as lojas de conveniência Br Mania da Vibra energia, para gestão de estoque das lojas, que envolvia a migração de tecnologia de aplicação Angular para React.  O time era composto por 4 desenvolvedores e 1 analista de qualidade.\n O projeto nesse período teve evoluções no front e back e suas principais tecnologias eram NestJS, Prisma, Postgres, React.Atividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerenciar pacotes de entrega.",
      }
    ];
  },
  getExperiences() {
    return [
      {
        "id": "4",
        "locale":"Metatron",
        "function": "Fullstack Developer",
        "period": "FEV/2015 - ABR/2018",
        "image": "metatron.jpeg",
      },
      {
        "id": "5",
        "locale":"Radix Engenharia e Software",
        "function": "Fullstack Developer",
        "period": "MAI/2018 - JAN/2021",
        "image": "radix.jpeg",
      },
      {
        "id": "6",
        "locale":"Oi S/A",
        "function": "Fullstack Developer",
        "period": "JAN/2021 - OUT/2021",
        "image": "oi.jpeg",
      },
      {
        "id": "7",
        "locale":"Vtal",
        "function": "Fullstack Developer",
        "period": "OUT/2021 - ABR/2022",
        "image": "vtal.jpeg",
      },
      {
        "id": "8",
        "locale":"Foursys",
        "function": "Fullstack Developer",
        "period": "ABR/2022 - FEV/2024",
        "image": "foursys.jpeg",
      },
      {
        "id": "9",
        "locale":"Neoris",
        "function": "Fullstack Developer",
        "period": "FEV/2018 - ABR/2024",
        "image": "neoris.jpeg",
      }
    ];
  },
  getExperiencesDetailed() {
    return [
      {
        "id": "4",
        "locale":"Metatron",
        "function": "Fullstack Developer",
        "period": "FEV/2015 - ABR/2018",
        "image": "metatron.jpeg",
        "description":"Participei no desenvolvimento e manutenção de uma aplicação web (OiVende) que era usado pelos lojistas da Oi para venda de planos de internet, celular e TV. Aplicação tinha como função apresentar viabilidade técnica de instalação, velocidade disponível, produtos disponíveis para a região do cliente e etc. Buscando essas informações de diversas fontes como APIs e Banco de Dados.\nEssa aplicação usava tecnologias como JSP, HTML, JS, CSS, LumisPortal, Java, Hibernate, Spring\nAtividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerenciar pacotes de entrega, acompanhamento das entregas em produção.",
      },
      {
        "id": "5",
        "locale":"Radix Engenharia e Software",
        "function": "Fullstack Developer",
        "period": "MAI/2018 - JAN/2021",
        "image": "radix.jpeg",
        "description":"Participei em diversos projetos do grupo Ipiranga e um dos projetos foi o desenvolvimento de uma aplicação que consultava os preços de compra dos combustíveis em grandes distribuidoras. Essa aplicação realizava cálculos com base em dados relevantes, como transporte do combustível, impostos, preços do dia, etc. \nAs tecnologias utilizadas eram Angular, Java, Spring, Hibernate, OracleDB, JUnit e a metodologia ágil foi aplicada para gerenciar as demandas do projeto.\nAlém disso, colaborei no desenvolvimento de uma aplicação mobile que facilitava a realização de pedidos de combustíveis para postos de gasolina. Essa aplicação foi construída usando tecnologias como React Native, Node.js, Express, etc.\nAtividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerenciar pacotes de entrega, repasse de projeto para outros times.",
      },
      {
        "id": "6",
        "locale":"Oi S/A",
        "function": "Fullstack Developer",
        "period": "JAN/2021 - OUT/2021",
        "image": "oi.jpeg",
        "description":"Participei de um time que era responsável pela verificação do repositório para identificar sites obsoletos e implementaria a higienização e atualização do layout de vários sites legados, criação de páginas novas, evolução e manutenção no backend.  \nAs tecnologias envolvidas incluíam HTML, CSS, JavaScript, React, JSP, Node, Java.\nAtividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerenciar pacotes de entrega.",
      },
      {
        "id": "7",
        "locale":"Vtal",
        "function": "Fullstack Developer",
        "period": "OUT/2021 - ABR/2022",
        "image": "vtal.jpeg",
        "description":"Implementação de Gateway para governança das APIs que são expostas aos clientes da infraestrutura da Vtal. Construção da página PortalDEV, que apresenta as APIs com suas documentações, Swagger e histórico de versionamento.\nUsamos tecnologias como React, Java, Node, API Gateway.\nAtividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerencia dos pacotes de entrega.",
      },
      {
        "id": "8",
        "locale":"Foursys",
        "function": "Fullstack Developer",
        "period": "ABR/2022 - FEV/2024",
        "image": "foursys.jpeg",
        "description":"Participei da evolução e manutenção do aplicativo móvel do Bradesco (App disponível para correntistas do banco). Com foco na área de investimentos do Bradesco, onde criei diversas telas, desenvolvi e mantive serviços. \nCriamos e alteramos micro frontends em angular para apresentar produtos novos (de parceiros como a Ágora), apresentar informações de novas funcionalidades e marketing. Os serviços são divididos em micro serviços como Node e Java e usamos ferramentas como Redis, Kafka no apoio das soluções.\nAtividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerenciar pacotes de entrega, repasse de artefatos para outros times, acompanhamento das entregas em produção, monitoramento da saúde de sistemas, análise de incidentes.\nAs principais demandas envolviam as tecnologias Angular, Node.js e Java.",
      },
      {
        "id": "9",
        "locale":"Neoris",
        "function": "Fullstack Developer",
        "period": "FEV/2018 - ABR/2024",
        "image": "neoris.jpeg",
        "description":"Participei de um projeto para as lojas de conveniência Br Mania da Vibra energia, para gestão de estoque das lojas, que envolvia a migração de tecnologia de aplicação Angular para React.  O time era composto por 4 desenvolvedores e 1 analista de qualidade.\n O projeto nesse período teve evoluções no front e back e suas principais tecnologias eram NestJS, Prisma, Postgres, React.Atividades incluíam documentação do desenvolvimento das features, execução e implementação de testes, levantamento e refino de requisitos, gerenciar pacotes de entrega.",
      }
    ];
  },
  getSkills() {
    return [
      {"id": "1","name":"Java",         "value":"5"},
      {"id": "2","name":"Angular",      "value":"4"},
      {"id": "3","name":"React",        "value":"5"},
      {"id": "4","name":"React Native", "value":"3"},
      {"id": "5","name":"NodeJS",       "value":"4"},
      {"id": "7","name":"SQL",          "value":"4"},
      {"id": "8","name":"NoSQL",        "value":"3"},
    ]
  },
  getGeneralSkills() {
    return [
      {"id": "1","name":"Spring (Boot, Data, Security)"},
      {"id": "2","name":"Maven"},
      {"id": "3","name":"Junit, Jest"},
      {"id": "4","name":"Docker"},
      {"id": "5","name":"CI/CD"},
      {"id": "7","name":"Métodos ágeis (Kanban, Scrum)"},
      {"id": "8","name":"Mobile (ReactNative, React+Webview, Angular)"},
      {"id": "9","name":"Microfrontend"},
      {"id": "10","name":"Microserviços"},
      {"id": "11","name":"AWS (SES, EC2, S3, RDS)"},
      {"id": "12","name":"Mensageria (Kafka)"},
      {"id": "13","name":"MongoDB, Redis"},
      {"id": "14","name":"Rest, GraphQL, SOAP, RPC, Websocket"},
      {"id": "15","name":"Prisma, TypeORM, Hibernate"},
    ]
  },
  getPersonInformations() {
    return {
      "personInfo": {
        "firstname":"Arthur",
        "lastname": "Reis",
        "middlename": "Rocha",
      },
      "contact": {
        "email": "arr90rj@gmail.com",
        "phone": "(21) 97129-9133",
        "linkedin": "linkedin.com/in/arthur-reis",
        "location": "RJ, Rio de Janeiro | Brasil"
      },
    }
  },
  getEducation() {
    return {
      "locale": "Centro Universitário Carioca",
      "degree": "Análise e desenvolvimento de sistemas",
      "period": "2020",
      "status": "finalizado",
    }
  },
};
